/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
import cookies from 'js-cookie';
import { checkoutKeyCookies } from '@config';

export const setGuest = (isGuest) => {
    cookies.set(checkoutKeyCookies.flagGuest, isGuest);
    return 0;
};

export const getGuestInfo = () => {
    const isGuest = cookies.get(checkoutKeyCookies.flagGuest);
    return parseInt(isGuest) || 0;
};

export const removeIsGuestFlagging = () => {
    cookies.remove(checkoutKeyCookies.flagGuest);
};

export const setCongig = (data) => {
    localStorage.setItem(checkoutKeyCookies.config, JSON.stringify(data));
    return true;
};

export const getConfig = () => {
    try {
        if (typeof window !== 'undefined') {
            let data = localStorage.getItem(checkoutKeyCookies.config);
            if (typeof data === 'string') data = JSON.parse(data);
            return data;
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const setAuth = (isLogin = 0) => {
    cookies.set(checkoutKeyCookies.flagAuthorization, isLogin);
    return 0;
};

export const getAuthInfo = () => {
    const isLogin = cookies.get(checkoutKeyCookies.flagAuthorization);
    return parseInt(isLogin) || 0;
};

export const removeIsAuthFlagging = () => {
    cookies.remove(checkoutKeyCookies.flagAuthorization);

    // add remove cookies on header and next-cookies
    // base on https://www.npmjs.com/package/next-cookies
    document.cookie = 'foo=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const setLogin = (isLogin = 0) => {
    const date = new Date();
    date.setHours(date.getHours() + 1);
    cookies.set(checkoutKeyCookies.flagLogin, isLogin, { expires: date });
    return 0;
};

export const setLoginDashboard = (isLogin = 0) => {
    cookies.set(checkoutKeyCookies.flagLoginDashboard, isLogin);
    return 0;
};

export const getLoginInfo = () => {
    const isLogin = cookies.get(checkoutKeyCookies.flagLogin);
    return parseInt(isLogin) || 0;
};

export const getLoginInfoDashboard = () => {
    const isLogin = cookies.get(checkoutKeyCookies.flagLoginDashboard);
    return parseInt(isLogin) || 0;
};

export const removeIsLoginFlagging = () => {
    cookies.remove(checkoutKeyCookies.flagLogin);

    // add remove cookies on header and next-cookies
    // base on https://www.npmjs.com/package/next-cookies
    document.cookie = 'foo=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const setLastPathWithoutLogin = (path) => {
    cookies.set('lastPathNoAuth', path);
    return true;
};

export const getLastPathWithoutLogin = () => {
    const path = cookies.get('lastPathNoAuth');
    if (path && typeof type !== 'undefined' && path !== '') {
        return path;
    }
    return '/customer/account';
};

export const removeLastPathWithoutLogin = () => {
    cookies.remove('lastPathNoAuth');
};

export const setEmailConfirmationFlag = ({ status, message, variant }) => {
    cookies.set('emailConfirmationFlag', { status, message, variant });
};

export const getEmailConfirmationFlag = () => {
    const emailConfirmationFlag = cookies.get('emailConfirmationFlag') || 0;
    return emailConfirmationFlag;
};

export const removeEmailConfirmationFlag = () => {
    cookies.remove('emailConfirmationFlag');
};
